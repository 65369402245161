import './SliderProducts.scss';

import { b2x } from '@b2x/react/src';
import classNames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { ProductTile } from '../ProductTile';
import { Slider, SliderBreakpoint, SliderResponsiveProps } from './Slider';
import { SliderButton } from './SliderButton';

export interface SliderProductsProps {
  className?: string;
  products?: Array<b2x.ProductApiDto>;
  responsiveSlider?: Partial<Record<SliderBreakpoint, SliderResponsiveProps>>;
  title?: string;
  titleClassName?: string;
}

export const SliderProducts = ({
  className,
  products,
  responsiveSlider,
  title,
  titleClassName,
}: SliderProductsProps) => {
  const sliderUUID = React.useMemo(() => `slider-${b2x.createUUID()}`, []);

  return (
    <div className={classNames('slider-products', className, sliderUUID)}>
      {title && (
        <div className="container-xxl">
          <div className="text-center mb-2">
            <h3 className={classNames('h2 text-blue', titleClassName)}>{title && b2x.formatHtml(title)}</h3>
          </div>
        </div>
      )}

      <div className="container-xxl">
        <b2x.EqualHeight>
          <div className="slider-container position-relative">
            <Slider
              navigation
              responsive={responsiveSlider}
              sliderNextEl={`.${sliderUUID} .swiper-button-next`}
              sliderPrevEl={`.${sliderUUID} .swiper-button-prev`}
              slidesPerView={1}
              spaceBetween={20}
            >
              {products?.map((product) => (
                <SwiperSlide key={product.id}>
                  <ProductTile enableExtraInfo product={product} />
                </SwiperSlide>
              ))}
            </Slider>

            <SliderButton direction="left" size="small" />
            <SliderButton direction="right" size="small" />
          </div>
        </b2x.EqualHeight>
      </div>
    </div>
  );
};

const defaultResponsiveSlider = {
  lg: {
    slidesPerView: 4,
  },
  md: {
    slidesPerView: 2,
  },
};

SliderProducts.defaultProps = {
  responsiveSlider: defaultResponsiveSlider,
};
