import './ProductPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import SwiperInterface, { Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useAppContext } from '../AppContext';
import { Breadcrumb } from '../Breadcrumb';
import { Button, ButtonVariant } from '../Button';
import { Container } from '../Container';
import { MiscellaneousContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { IconName, IconSize } from '../Icon';
import { Slider } from '../slider/Slider';
import { SliderButton } from '../slider/SliderButton';
import { SliderProducts } from '../slider/SliderProducts';
import { Page } from './Page';

export interface ProductPagePros {}

export const ProductPage = (props: ProductPagePros) => {
  const product = b2x.useProduct({ populate: b2x.appConfig.api?.productPopulate });

  const miscellaneousContent = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');
  const { headerHeight, isFooterCopyrightVisible, isTopBarRowRefVisible } = useAppContext();

  const [skusDivContainer, scrollToSkusDivContainer] = b2x.useScrollTo(-180);

  const backgroundImgSx = product?.attributes?.find(
    (attribute) => attribute.typeCode === 'GEN_IMG_BACKGROUND_SX'
  )?.value;
  const backgroundImgDx = product?.attributes?.find(
    (attribute) => attribute.typeCode === 'GEN_IMG_BACKGROUND_DX'
  )?.value;

  const descriptionImg = product?.attributes?.find((attribute) => attribute.typeCode === 'GEN_IMG_DESCRIPTION')?.value;
  const descriptionTitle =
    product?.attributes?.find((attribute) => attribute.typeCode === 'GEN_TXT_DESCRIPTION_TITLE')?.value ??
    (product?.assembled
      ? miscellaneousContent?.body.productPage?.title?.kitDescription?.toLowerCase()
      : miscellaneousContent?.body.productPage?.title?.description?.toLowerCase());

  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <Page
      className="product-page"
      noPaddingBottom
      noPaddingTop
      thingsToLoadBeforePageReady={[product]}
      transparentHeader={!b2x.untilBreakpoint('lg', currentBreakpoint)}
    >
      {product !== undefined && (
        <b2x.ProductContext product={product}>
          {(productContext) => (
            <>
              <b2x.AddToCartFormHelper product={product} scope="product">
                {({ fieldsHelper, formik, priceHelper, selectedProductVariant, selectedSku }) => (
                  <>
                    <section className="main bg-powder-light" style={{ paddingTop: headerHeight }}>
                      <Container>
                        <div className="pt-lg-3 pt-xl-4">
                          <b2x.Row className="main-row" gap={0}>
                            <b2x.Col className="pe-lg-3" size={{ lg: 6, xs: 12 }}>
                              <b2x.ImageAsBackgroundFromContentV1
                                backgroundSize="contain"
                                className="background-sx"
                                src={{ xs: { url: backgroundImgSx } }}
                              />
                              <div className="position-relative">
                                <div className="btn-wishlist-container position-absolute end-0 p-3">
                                  <b2x.WishlistButtonHelper product={product} sku={selectedSku}>
                                    {({ handleWishlistButtonClick, inWishlist }) => (
                                      <Button
                                        className="text-secondary"
                                        iconEnd={{
                                          name: inWishlist ? 'wishlist-full' : 'wishlist',
                                          size: 30,
                                        }}
                                        onClick={handleWishlistButtonClick}
                                        variant="blank"
                                      />
                                    )}
                                  </b2x.WishlistButtonHelper>
                                </div>
                                <ProductGallery
                                  selectedProductVariant={selectedProductVariant}
                                  selectedSku={selectedSku}
                                  thumbnailWidh={100}
                                />
                              </div>
                            </b2x.Col>
                            <b2x.Col className="ps-lg-3" size={{ lg: 6, xs: 12 }}>
                              <b2x.ImageAsBackgroundFromContentV1
                                backgroundSize="contain"
                                className="background-dx"
                                src={{ xs: { url: backgroundImgDx } }}
                              />
                              <div className="main-info-container py-5 px-3 p-lg-0">
                                <div className="pb-lg-3">
                                  <Breadcrumb />
                                </div>
                                <div className="main-info-content" ref={skusDivContainer}>
                                  {product.name && <h1 className="h2 text-blue mb-2">{product.name}</h1>}

                                  {product.assembled ? (
                                    <div className="text-blue small mb-3">
                                      {miscellaneousContent && (
                                        <h6 className="fw-bold">{miscellaneousContent.body.productPage?.title?.kit}</h6>
                                      )}
                                      {product.assembledComponents && product.assembledComponents.length > 0 && (
                                        <ul className="list-unstyled">
                                          {product.assembledComponents.map((assembledComponent, index) => {
                                            const sku = assembledComponent.componentSkus?.at(0)?.sku;
                                            return (
                                              // eslint-disable-next-line react/no-array-index-key
                                              <li className="lh-sm" key={`assembledComponent-${index}`}>
                                                <b>{assembledComponent.defaultQty}x</b> {sku?.product?.name}
                                                {sku?.measurement &&
                                                  ` (${
                                                    sku.measurement.value
                                                  } ${sku.measurement.measurementUnit?.toLocaleLowerCase()})`}
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      )}
                                    </div>
                                  ) : (
                                    selectedSku?.name && (
                                      <div className="text-blue mb-3">
                                        <h6>{b2x.formatHtml(selectedSku.name)}</h6>
                                        {product.descriptionShort && (
                                          <div className="mt-3">{b2x.formatHtml(product.descriptionShort)}</div>
                                        )}
                                      </div>
                                    )
                                  )}
                                  {selectedSku?.code && (
                                    <p className="extra-small text-gray-500 mb-2">
                                      {t('product.code')}: {selectedSku.code}
                                    </p>
                                  )}
                                  <div className="product-price-container mb-3">
                                    <b2x.PriceBlock gap={3} priceHelper={priceHelper} />
                                    {b2x.appConfig.enableBestPrice && priceHelper.bestPriceValue && (
                                      <div className="small">
                                        <b2x.BestPrice priceHelper={priceHelper} />
                                      </div>
                                    )}
                                  </div>
                                  {fieldsHelper.productVariants.formFields.length > 1 && (
                                    <b2x.FormGroup
                                      {...fieldsHelper.productVariants.formGroup}
                                      className="product-variant bg-powder-light pt-2 p-3 d-inline-block"
                                      label={undefined}
                                    >
                                      <div>
                                        <h6 className="small mb-1">{t('form.productForm.size')}</h6>
                                        <div className="product-variant-container d-flex flex-wrap gap-2 small">
                                          {fieldsHelper.productVariants.formFields.map((formField, index) => (
                                            <b2x.Radio
                                              key={formField.productVariant.id}
                                              {...formField.radio}
                                              inline
                                              labelClassName="fw-bold px-2"
                                            >
                                              {formField.productVariant.skus?.map((sku, indexSku) => (
                                                <React.Fragment key={sku.id}>
                                                  {indexSku === 0 &&
                                                  sku.measurement?.value &&
                                                  sku.measurement.measurementUnit ? (
                                                    <span className="text-lowercase">
                                                      {`${sku.measurement.value} ${sku.measurement.measurementUnit}`}
                                                    </span>
                                                  ) : (
                                                    sku.name
                                                  )}
                                                </React.Fragment>
                                              ))}
                                            </b2x.Radio>
                                          ))}
                                        </div>
                                      </div>
                                    </b2x.FormGroup>
                                  )}
                                  <div className="d-flex">
                                    <b2x.FormGroup
                                      {...fieldsHelper.quantity.formGroup}
                                      className="text-center me-3"
                                      label={undefined}
                                      noMarginBottom
                                    >
                                      <b2x.Select
                                        {...fieldsHelper.quantity.select}
                                        includeEmptyOption={false}
                                        placeholder={undefined}
                                        style={{ maxWidth: '80px' }}
                                      />
                                    </b2x.FormGroup>
                                    {/* <b2x.Button {...fieldsHelper.buttons.submit} className="px-3 text-uppercase" /> */}
                                    <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                                      fieldsHelper={fieldsHelper}
                                      selectedSku={selectedSku}
                                      submitButton={{ className: 'px-3' }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </b2x.Col>
                          </b2x.Row>
                        </div>
                      </Container>
                    </section>
                    <section className="copy text-secondary py-lg-5 mb-5">
                      <Container>
                        <b2x.Row gap={0}>
                          <b2x.Col className="media-container offset-lg-1 order-lg-2" size={{ lg: 6, xs: 12 }}>
                            {descriptionImg ? (
                              <b2x.Image className="mb-5 mb-lg-0" fluid src={descriptionImg} />
                            ) : (
                              miscellaneousContent?.body.productPage?.descriptionImage && (
                                <b2x.ImageFromContentV1
                                  className="mb-5 mb-lg-0"
                                  {...miscellaneousContent.body.productPage.descriptionImage}
                                  fluid
                                />
                              )
                            )}
                          </b2x.Col>
                          <b2x.Col size={{ lg: 5, xs: 12 }}>
                            <div className="px-3">
                              {descriptionTitle && (
                                <h2 className="display-1 mb-3">{b2x.formatHtml(descriptionTitle)}</h2>
                              )}
                              {product.description && <div className="fs-5">{b2x.formatHtml(product.description)}</div>}
                            </div>
                          </b2x.Col>
                        </b2x.Row>
                      </Container>
                    </section>
                    <ProductIngredients content={miscellaneousContent} product={product} />
                    <b2x.Portal>
                      <b2x.ProductStickyFooter
                        fieldsHelper={fieldsHelper}
                        formik={formik}
                        isVisible={!isTopBarRowRefVisible && !isFooterCopyrightVisible}
                        priceHelper={priceHelper}
                        product={product}
                        scrollToElement={scrollToSkusDivContainer}
                        selectedProductImage={selectedProductVariant.image}
                        selectedSku={selectedSku}
                      />
                    </b2x.Portal>
                  </>
                )}
              </b2x.AddToCartFormHelper>
              {productContext.selectedProductVariant.id && (
                <RelatedProducts content={miscellaneousContent} productId={productContext.selectedProductVariant.id} />
              )}
            </>
          )}
        </b2x.ProductContext>
      )}
    </Page>
  );
};

interface ProductIngredientsProps {
  content?: b2x.ContentApiDto<MiscellaneousContentType>;
  product: b2x.ProductApiDto;
}

const ProductIngredients = ({ content, product }: ProductIngredientsProps) => {
  const cookiesSliderNextButton = React.useRef<HTMLButtonElement>(null);
  const cookiesSliderPrevButton = React.useRef<HTMLButtonElement>(null);

  const [selectedProduct, setSelectedProduct] = React.useState<b2x.ProductApiDto>(product);
  const [assembledComponentIndex, setAssembledComponentIndex] = React.useState<number>(0);

  React.useEffect(() => {
    setSelectedProduct(product);
    if (product.assembled) {
      product.assembledComponents &&
        product.assembledComponents[assembledComponentIndex].componentSkus?.forEach(
          (componentSku, index) =>
            index === 0 && componentSku.sku?.product && setSelectedProduct(componentSku.sku.product)
        );
    }
  }, [product.assembled, product.assembledComponents, assembledComponentIndex, selectedProduct, product]);

  const transparentProductImage = selectedProduct.attributes?.find(
    (attribute) => attribute.typeCode === 'GEN_IMG_TRANSPARENT_PRODUCT'
  )?.value;

  const pillars = [
    selectedProduct.attributes?.find((attribute) => attribute.typeCode === 'GEN_IMG_PILLAR_1')?.value,
    selectedProduct.attributes?.find((attribute) => attribute.typeCode === 'GEN_IMG_PILLAR_2')?.value,
    selectedProduct.attributes?.find((attribute) => attribute.typeCode === 'GEN_IMG_PILLAR_3')?.value,
  ];

  const productIngredients = selectedProduct.attributes?.find(
    (attribute) => attribute.typeCode === 'Ingredienti'
  )?.value;

  const productNutritionalTable = selectedProduct.attributes?.find(
    (attribute) => attribute.typeCode === 'GEN_valore_nut_html'
  )?.value;

  return productIngredients || productNutritionalTable || product.assembledComponents ? (
    <section className="ingredients bg-powder-light">
      {product.assembled ? (
        <div className={classnames('products-slider', { 'pt-5': productIngredients }, { 'py-5': !productIngredients })}>
          <Container>
            <b2x.Row className="justify-content-center">
              <b2x.Col className="text-center" size={{ xl: 10, xs: 12 }}>
                {content?.body.productPage?.title?.kit && (
                  <h3 className="fs-2 fw-bold text-secondary mb-3">{content.body.productPage.title.kit}</h3>
                )}
                {content?.body.productPage?.ingredientsSelectProduct && (
                  <p className="text-secondary mb-3">
                    {b2x.formatHtml(content.body.productPage.ingredientsSelectProduct)}
                  </p>
                )}
                <div className="position-relative">
                  <b2x.EqualHeight>
                    <b2x.Row>
                      <b2x.Col className="d-none d-lg-block" size={1}>
                        <b2x.EqualHeightElement name={'ingredient-slider-element'}>
                          <SliderButton direction="left" innerRef={cookiesSliderPrevButton} size="large" />
                        </b2x.EqualHeightElement>
                      </b2x.Col>
                      <b2x.Col size={{ lg: 10, xs: 12 }}>
                        <Slider
                          centeredSlides
                          loop
                          navigation
                          // eslint-disable-next-line react/jsx-no-bind
                          onSlideChange={(swiper) => setAssembledComponentIndex(swiper.realIndex)}
                          responsive={{
                            lg: {
                              slidesPerView: 5,
                            },
                            sm: {
                              slidesPerView: 3.5,
                            },
                            xl: {
                              slidesPerView: 7,
                            },
                          }}
                          slideToClickedSlide
                          sliderNextEl={cookiesSliderNextButton}
                          sliderPrevEl={cookiesSliderPrevButton}
                          slidesPerView={1.75}
                          spaceBetween={20}
                        >
                          {product.assembledComponents?.map((assembledComponent) => (
                            <SwiperSlide key={assembledComponent.code}>
                              {(slideData) =>
                                assembledComponent.componentSkus && (
                                  <>
                                    {assembledComponent.componentSkus[0].sku?.product?.image && (
                                      <b2x.EqualHeightElement name={'ingredient-slider-element'}>
                                        <b2x.Image
                                          {...assembledComponent.componentSkus[0].sku.product.image}
                                          className="mb-2"
                                          fluid
                                        />
                                      </b2x.EqualHeightElement>
                                    )}
                                    {assembledComponent.componentSkus[0].sku?.product?.name && (
                                      <h6 className="small text-secondary fw-bold">
                                        {assembledComponent.componentSkus[0].sku.product.name}
                                      </h6>
                                    )}
                                  </>
                                )
                              }
                            </SwiperSlide>
                          ))}
                        </Slider>
                      </b2x.Col>
                      <b2x.Col className="d-none d-lg-block" size={1}>
                        <b2x.EqualHeightElement name={'ingredient-slider-element'}>
                          <SliderButton direction="right" innerRef={cookiesSliderNextButton} size="large" />
                        </b2x.EqualHeightElement>
                      </b2x.Col>
                    </b2x.Row>
                  </b2x.EqualHeight>
                </div>
              </b2x.Col>
            </b2x.Row>
          </Container>
        </div>
      ) : (
        <div className="image">
          <Container>
            <div className="d-flex justify-content-center">
              <b2x.Image fluid src={transparentProductImage} style={{ maxWidth: '250px' }} />
            </div>
          </Container>
        </div>
      )}
      {productIngredients && (
        <div className="copy pt-3 pb-3 pb-lg-5">
          <Container>
            <b2x.Row>
              <b2x.Col className="offset-lg-2" size={{ lg: 8, xs: 12 }}>
                {content?.body.productPage?.title?.ingredients && (
                  <h3 className="fs-2 fw-bold text-secondary text-center mb-3">
                    {content.body.productPage.title.ingredients}
                  </h3>
                )}
                <div className="text-secondary">{b2x.formatHtml(productIngredients)}</div>
              </b2x.Col>
            </b2x.Row>
          </Container>
        </div>
      )}
      {pillars.length > 0 && (
        <div className="ingredients-slider">
          <Container>
            <b2x.Row className="justify-content-center">
              <b2x.Col size={{ lg: 8, xs: 12 }}>
                <div className="position-relative">
                  <Slider
                    responsive={{
                      md: {
                        slidesPerView: 3,
                        spaceBetween: 60,
                      },
                      sm: {
                        slidesPerView: 2.5,
                      },
                    }}
                    slideToClickedSlide
                    slidesPerView={2.5}
                    spaceBetween={20}
                  >
                    {pillars.map(
                      (pillar, i) =>
                        pillar && (
                          // eslint-disable-next-line react/no-array-index-key
                          <SwiperSlide className="text-center" key={pillar + i}>
                            {(slideData) => (
                              <b2x.Image className="bg-white rounded-circle overflow-hidden" fluid src={pillar} />
                            )}
                          </SwiperSlide>
                        )
                    )}
                  </Slider>
                </div>
              </b2x.Col>
            </b2x.Row>
          </Container>
        </div>
      )}
      {productNutritionalTable && (
        <div className="nutritional-value bg-orange-light text-center pt-3 pb-5 py-lg-5">
          <Container>
            {content?.body.productPage?.title?.nutritionalValue && (
              <h3 className="fs-2 fw-bold text-secondary mb-3">{content.body.productPage.title.nutritionalValue}</h3>
            )}
            <b2x.Row>
              <b2x.Col className="offset-lg-2" size={{ lg: 8, xs: 12 }}>
                <div className="table-responsive text-secondary border border-white rounded-2 py-3 px-4 lh-sm">
                  {b2x.formatHtml(productNutritionalTable)}
                </div>
              </b2x.Col>
            </b2x.Row>
          </Container>
        </div>
      )}
    </section>
  ) : (
    <></>
  );
};

interface RelatedProductsProps {
  content?: b2x.ContentApiDto<MiscellaneousContentType>;
  productId: string;
}

// Rimuovere l'export dopo la correzione del loop
export const RelatedProducts = ({ content, productId }: RelatedProductsProps) => {
  /*   const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);
 */
  return (
    <b2x.RelatedProducts productId={productId}>
      {({ fetching, relatedProducts }) =>
        fetching ? (
          <b2x.Loading />
        ) : (
          relatedProducts &&
          relatedProducts.length > 0 && (
            <section className="related py-5">
              <Container>
                <b2x.Listing name={`Product page - ${t('misc.ourSelection')}`} products={relatedProducts}>
                  <SliderProducts
                    className="my-3 my-lg-5"
                    products={relatedProducts}
                    title={content?.body.productPage?.title?.related ? content.body.productPage.title.related : ''}
                    titleClassName="fw-bold"
                  />
                </b2x.Listing>
              </Container>
            </section>
          )
        )
      }
    </b2x.RelatedProducts>
  );
};

interface ProductGalleryProps {
  selectedProductVariant: b2x.ProductApiDto;
  selectedSku?: b2x.SkuApiDto;
  thumbnailWidh: number;
}

const ProductGallery = ({ selectedProductVariant, selectedSku, thumbnailWidh }: ProductGalleryProps) => {
  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperInterface>();

  const handleOnSlideChange = React.useCallback(
    (swiper: SwiperInterface) => {
      thumbsSwiper?.slideTo(swiper.realIndex - 1);
    },
    [thumbsSwiper]
  );

  const images = React.useMemo(
    () => [
      selectedSku?.image ? selectedSku.image : selectedProductVariant.image,
      ...(selectedProductVariant.alternativeImages ? selectedProductVariant.alternativeImages : []),
    ],
    [selectedProductVariant.alternativeImages, selectedProductVariant.image, selectedSku?.image]
  );

  return (
    <div className="product-gallery">
      <div className="slides ps-0 ps-lg-3" style={{ maxWidth: `calc(100% - ${thumbnailWidh}px)` }}>
        <b2x.Lightbox>
          <Swiper
            modules={[Thumbs, Navigation]}
            navigation
            // eslint-disable-next-line react/jsx-no-bind
            onSlideChange={(swiper) => handleOnSlideChange(swiper)}
            slidesPerView={1}
            spaceBetween={0}
            thumbs={{
              swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            watchSlidesProgress
          >
            {images.map(
              (image, index) =>
                image?.src && (
                  <SwiperSlide
                    className="text-center"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`product-slide-${index}`}
                  >
                    {(slideData) =>
                      typeof image.src === 'string' && (
                        <b2x.LightboxItem src={image.src}>
                          <b2x.Image aspectRatio={b2x.appConfig.productImageAspectRatio} fluid src={image.src} />
                        </b2x.LightboxItem>
                      )
                    }
                  </SwiperSlide>
                )
            )}
          </Swiper>
        </b2x.Lightbox>
      </div>
      <div className="thumbnails d-none d-lg-block" style={{ maxWidth: thumbnailWidh, width: '100%' }}>
        <Swiper
          direction="vertical"
          modules={[Thumbs]}
          // eslint-disable-next-line react/jsx-no-bind
          onSwiper={setThumbsSwiper}
          slidesPerView={'auto'}
          spaceBetween={16}
          watchSlidesProgress
        >
          {images.map(
            (image, index) =>
              image && (
                <SwiperSlide
                  className="bg-white border border-color-gray-300 rounded overflow-hidden"
                  // eslint-disable-next-line react/no-array-index-key
                  key={`product-thumbnail-${index}`}
                >
                  {(slideData) => (
                    <b2x.Image aspectRatio={b2x.appConfig.productImageAspectRatio} fluid src={image.src} />
                  )}
                </SwiperSlide>
              )
          )}
        </Swiper>
      </div>
    </div>
  );
};
