import { Resource } from '../i18n';

export const it: Resource = {
  form: {
    addToCartForm: {
      buttons: {
        submit: { label: 'Aggiungi al carrello' },
      },
    },
    personalDataForm: {
      phoneNumbers: { label: 'Telefono' },
    },
    productForm: {
      size: 'Formato',
    },
  },
  misc: {
    filterByProduct: 'Filtra per prodotto',
    noItemFound: 'Nessun elemento trovato',
    ourSelection: 'La nostra selezione',
    storeName: 'Gentilini',
  },
  welcome: 'Benvenuto su store-gentilini!',
};
