import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { CategoryItemRow } from '../CategoryItemRow';
import { CategoryProductPreview } from '../CategoryProductPreview';
import { Container } from '../Container';
import { CategoryLandingPageContentType } from '../contentTypes';
import { Page } from './Page';

export interface CategoryLandingPageProps {}

export const CategoryLandingPage = (props: CategoryLandingPageProps) => {
  const page = b2x.usePage<CategoryLandingPageContentType>();

  const body = page?.content?.body;

  return (
    <Page thingsToLoadBeforePageReady={[page]}>
      <Container>
        <div className="text-center text-blue mt-5">
          <h1>{body?.firstRow?.title}</h1>
          <b2x.Row className="mt-5">
            {body?.firstRow?.productList?.map((product) => (
              <b2x.Col
                className="fade-in-image-animation-container visible"
                key={product.contentSectionId}
                size={{ md: '', xs: 6 }}
              >
                <CategoryProductPreview {...product} />
              </b2x.Col>
            ))}
          </b2x.Row>
          {body?.firstRow?.cta && body.firstRow.cta.label && (
            <b2x.DeprecatedCta
              className={classNames('btn text-white mt-5', `btn-${body.firstRow.cta.variant}`)}
              cta={body.firstRow.cta}
            >
              {body.firstRow.cta.label}
            </b2x.DeprecatedCta>
          )}
        </div>
      </Container>
      <div className="mt-5">
        {body?.rowList?.map((row) => (
          <CategoryItemRow key={row.contentSectionId} {...row} />
        ))}
      </div>
    </Page>
  );
};
