export const en = {
  form: {
    personalDataForm: {
      phoneNumbers: { label: 'Telephone' },
    },
    productForm: {
      size: 'Size',
    },
  },
  misc: {
    filterByProduct: 'Filter by product',
    noItemFound: 'No item found',
    ourSelection: 'Our selection',
    storeName: 'Gentilini',
  },
  welcome: 'Welcome to Gentilini',
};
