import './RecipePage.scss';

import { b2x } from '@b2x/react/src';
import { Fragment } from 'react';

import { useAppContext } from '../AppContext';
import { Breadcrumb } from '../Breadcrumb';
import { Container } from '../Container';
import { RecipePageContentType } from '../contentTypes';
import { SliderProducts } from '../slider/SliderProducts';
import { Page } from './Page';

export interface RecipePageProps {}

// const fakeItem = {
//   contentSectionId: 1,
//   difficulty: 'facile',
//   tag: 'Amaretti',
//   time: "2'",
//   title: 'Crumble di Amaretti Gentilini e fragole',
// };

// const fakeItemList = [
//   {
//     ...fakeItem,
//     contentSectionId: 1,
//     img: { src: { xs: 'https://www.b2x.it/rest/images/2023/05/22/1458631.png?imageFormat=@1x' } },
//   },
//   {
//     ...fakeItem,
//     contentSectionId: 2,
//     img: { src: { xs: 'https://www.b2x.it/rest/images/2023/05/19/1458208.png?imageFormat=@1x' } },
//   },
//   {
//     ...fakeItem,
//     contentSectionId: 3,
//     img: { src: { xs: 'https://www.b2x.it/rest/images/2023/05/19/1458177.png?imageFormat=@1x' } },
//   },
// ];

export const RecipePage = (props: RecipePageProps) => {
  const { headerHeight } = useAppContext();
  const page = b2x.usePage<RecipePageContentType>({
    populate: {
      products: b2x.appConfig.api?.productTilePopulate,
    },
  });

  const body = page?.content?.body;
  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <Page
      className="recipe-page"
      noPaddingTop
      thingsToLoadBeforePageReady={[page]}
      transparentHeader={!b2x.untilBreakpoint('lg', currentBreakpoint)}
    >
      <section
        className="main bg-powder-light"
        style={{
          paddingTop: headerHeight,
        }}
      >
        <div className="mt-lg-5">
          <Container>
            <b2x.Row>
              <b2x.Col className="px-0 px-0 px-lg-3 text-center" size={{ lg: 6, xs: 12 }}>
                <b2x.ImageFromContentV1 fluid {...body?.firstRow?.img} className="recipe-img" />
              </b2x.Col>
              <b2x.Col className="px-0 px-lg-3 position-relative" size={{ lg: 6, xs: 12 }}>
                <div className="px-3 px-lg-0 px-xl-3">
                  <Breadcrumb />
                  <h1 className="text-secondary h3 fw-bold mt-3 mt-md-0">{body?.firstRow?.title}</h1>
                  {body?.firstRow?.itemList?.map((item) => {
                    return (
                      <Fragment key={item.contentSectionId}>
                        <h3 className="text-secondary fw-bold mb-0 mt-3 h5 pb-2">{b2x.formatHtml(item.title)}</h3>
                        <p className="text-secondary mb-0">{b2x.formatHtml(item.content)}</p>
                      </Fragment>
                    );
                  })}
                </div>
              </b2x.Col>
            </b2x.Row>
          </Container>
          {/* {miscellaneousContent?.body.recipePageTopBgImage && (
            <b2x.ImageFromContentV1
              className="backgroundImgDx d-lg-block d-none"
              style={{ paddingTop: headerHeight }}
              {...miscellaneousContent.body.recipePageTopBgImage}
            />
          )} */}
        </div>
      </section>
      <Container>
        <b2x.Row className="mt-5 px-3 px-lg-5">
          <b2x.Col
            className="px-0 px-lg-3 pt-lg-4"
            size={{ lg: page?.products && page.products.length > 0 ? 8 : 12, xs: 12 }}
          >
            <h3 className="text-secondary display-1 text-lg-start text-center">{body?.secondRow?.leftCol?.title}</h3>
            <p className="text-secondary mt-3">{b2x.formatHtml(body?.secondRow?.leftCol?.description)}</p>
          </b2x.Col>
          {page?.products && page.products.length > 0 && (
            <b2x.Col className="px-0 px-lg-3 pt-lg-4" size={{ lg: 4, xs: 12 }}>
              <b2x.Listing name={`Recipe page - ${body?.secondRow?.rightCol?.title}`} products={page.products}>
                <SliderProducts
                  className="mt-3 mt-lg-0"
                  products={page.products}
                  responsiveSlider={{ sm: { slidesPerView: 1 } }}
                  title={body?.secondRow?.rightCol?.title}
                  titleClassName="text-secondary display-1 h3"
                />
              </b2x.Listing>
            </b2x.Col>
          )}
        </b2x.Row>
      </Container>
      {/* <Container>
        <SliderRecipes itemList={fakeItemList} title="Ti potrebbe interessare anche" />
      </Container> */}
    </Page>
  );
};
