import './LottieElement.scss';

import { IPlayerProps, Player } from '@lottiefiles/react-lottie-player';

export interface LottieElementProps extends IPlayerProps {
  height?: string;
  src: string;
  width?: string;
}

export const LottieElement = (props: LottieElementProps) => {
  const { autoplay = true, height, loop = true, src, width, ...otherProps } = props;
  return <Player {...otherProps} autoplay={autoplay} loop={loop} src={src} style={{ height: height, width: width }} />;
};
