import React from 'react';

import { b2x } from '.';

export interface UseAncestorContentOptions<T> {
  defaultContent?: b2x.ContentApiDto<T>;
  page?: b2x.PageApiDto<T>;
}

export const useAncestorContent = <T>(
  options: UseAncestorContentOptions<T>,
  findCondition?: (value: b2x.PageApiDto<T>) => boolean
) => {
  const { defaultContent, page: { breadcrumb } = {} } = options;

  const breadcrumbMemo = React.useMemo(
    () => (breadcrumb ? (breadcrumb as Array<b2x.PageApiDto<T>>) : []),
    [breadcrumb]
  );

  return React.useMemo<b2x.ContentApiDto<T> | undefined>(
    () =>
      b2x.getTypedContent<T>(
        b2x.findLast(breadcrumbMemo, (value) => !!value.content && (!findCondition || findCondition(value)))?.content
      ) ?? defaultContent,
    [breadcrumbMemo, defaultContent, findCondition]
  );
};
