import { b2x } from '@b2x/react/src';
import { SwiperSlide } from 'swiper/react';

import { Icon, isIconName } from '../Icon';
import { Slider } from './Slider';

export interface SliderVerticalProps {
  itemList?: Array<{
    cta?: b2x.contentTypes.CtaContentSection;
  }>;
}
export const SliderVertical = ({ itemList }: SliderVerticalProps) => {
  return (
    <Slider
      autoHeight
      className="SliderVertical"
      sliderAutoPlay={itemList && itemList.length > 1 ? 3000 : undefined}
      slidesPerView={1}
      vertical
    >
      {itemList &&
        itemList.map((item, index) => (
          <SwiperSlide
            className="d-flex justify-content-center align-items-center extra-small text-uppercase"
            key={item.cta?.label}
          >
            {(slideData) =>
              item.cta ? (
                <b2x.DeprecatedCta className="text-reset text-decoration-none d-flex align-items-center" cta={item.cta}>
                  {item.cta.icon && isIconName(item.cta.icon) && (
                    <Icon className="me-1" name={item.cta.icon} size={16} />
                  )}
                  {item.cta.label}
                </b2x.DeprecatedCta>
              ) : (
                <></>
              )
            }
          </SwiperSlide>
        ))}
    </Slider>
  );
};
