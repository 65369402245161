import './PriceBlock.scss';

import { b2x } from '@b2x/react/src';

export type PriceBlockProps = b2x.PriceBlockProps;

export const PriceBlock = ({ classNames, ...otherProps }: PriceBlockProps) => {
  return (
    <b2x.PriceBlock
      {...otherProps}
      alignItems={'center'}
      classNames={{
        crossedOutPrice: `text-blue ${classNames?.crossedOutPrice}`,
        discountPercentage: `small text-white bg-red rounded-1 px-2 ${classNames?.discountPercentage}`,
        price: `fw-bold text-blue ${classNames?.price}`,
        specialPrice: `fw-bold text-red ${classNames?.specialPrice}`,
      }}
    />
  );
};
