import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

export interface SectionProps {
  children?: React.ReactNode;
  className?: string;
  noMarginY?: boolean;
  noPaddingX?: boolean;
  subtitle?: string;
  textAlignment?: b2x.ResponsiveTextAlign;
  title?: string;
}
export const Section = ({
  children,
  className,
  noMarginY,
  noPaddingX,
  subtitle,
  textAlignment = 'center',
  title,
}: SectionProps) => {
  return (
    <section className={classnames({ 'my-3': !noMarginY }, className)}>
      <div className={classnames('container-xxl', { 'px-0': noPaddingX })}>
        {(title || subtitle) && (
          <div className={classnames('row justify-content-center', { 'g-0': noPaddingX })}>
            <div className="col-xxl-11">
              <div
                className={classnames('py-3 py-lg-5', b2x.buildTextAlignClassNames(textAlignment), {
                  'px-3': noPaddingX,
                })}
              >
                {title && <h2 className="text-blue mb-3 fw-bold title">{b2x.formatHtml(title)}</h2>}
                {subtitle && <h4 className="sub-title">{b2x.formatHtml(subtitle)}</h4>}
              </div>
            </div>
          </div>
        )}
        {children && <div className="section-content">{children}</div>}
      </div>
    </section>
  );
};
