import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { useAppContext, useAppStaticContext } from '../AppContext';
import { MiscellaneousContentType } from '../contentTypes';

export interface PageProps extends b2x.PageProps {
  backgroundImage?: b2x.contentTypes.ImageContentSectionV1;
  headerCheckout?: boolean;
  noPaddingBottom?: boolean;
  noPaddingTop?: boolean;
  powderLightBackground?: boolean;
  transparentHeader?: boolean;
  withGenericBackgroundImage?: 'top' | 'bottom';
}

export const Page = ({
  backgroundImage,
  children,
  className,
  headerCheckout = false,
  noPaddingBottom = false,
  noPaddingTop = false,
  powderLightBackground = false,
  transparentHeader = false,
  withGenericBackgroundImage,
  ...otherProps
}: PageProps) => {
  const { pageReady } = b2x.useAppContext();
  const { headerHeight } = useAppContext();
  const { setHeaderCheckout, setPowderLightBackgroundPage, setTransparentHeader } = useAppStaticContext();

  const content = b2x.useContent<MiscellaneousContentType>('MISCELLANEOUS_CONTENT');
  React.useEffect(() => {
    pageReady && setTransparentHeader(transparentHeader);
    pageReady && setHeaderCheckout(headerCheckout);
    pageReady && setPowderLightBackgroundPage(powderLightBackground);
  }, [
    headerCheckout,
    setHeaderCheckout,
    pageReady,
    powderLightBackground,
    setPowderLightBackgroundPage,
    transparentHeader,
    setTransparentHeader,
  ]);

  React.useEffect(() => {
    if (powderLightBackground) {
      document.body.classList.add('bg-powder-light');
    } else {
      document.body.classList.remove('bg-powder-light');
    }
  }, [powderLightBackground]);

  return (
    <b2x.Page {...otherProps} className={classnames(className, 'h-100')}>
      <div
        className={classnames(
          'Page h-100 position-relative',
          { hiddenBgLg: withGenericBackgroundImage },
          { 'transparent-header': transparentHeader }
        )}
        style={
          withGenericBackgroundImage
            ? {
                backgroundImage:
                  withGenericBackgroundImage === 'top' && content?.body.backgroundImageTopPage
                    ? `url(${content.body.backgroundImageTopPage})`
                    : withGenericBackgroundImage === 'bottom' && content?.body.backgroundImageBottomPage?.url
                    ? `url(${content.body.backgroundImageBottomPage.url})`
                    : 'none',
                backgroundPosition: `center ${withGenericBackgroundImage}`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                marginBottom: transparentHeader && headerHeight !== undefined ? -headerHeight : undefined,
                top: transparentHeader && headerHeight !== undefined ? -headerHeight : undefined,
              }
            : {
                marginBottom: transparentHeader && headerHeight !== undefined ? -headerHeight : undefined,
                top: transparentHeader && headerHeight !== undefined ? -headerHeight : undefined,
              }
        }
      >
        {backgroundImage && (
          <div
            className="position-absolute w-100"
            style={transparentHeader ? { height: `calc(100% + ${headerHeight}px)` } : { height: '100%' }}
          >
            <b2x.ImageAsBackgroundFromContentV1 {...backgroundImage} />
          </div>
        )}
        <div
          className={classnames(
            'position-relative',
            'py-3 py-xl-4',
            { 'pt-0 pt-xl-0': noPaddingTop },
            { 'pb-0 pb-xl-0': noPaddingBottom }
          )}
          // style={{
          //   marginBottom: transparentHeader && headerHeight !== undefined ? -headerHeight : undefined,
          //   top: transparentHeader && headerHeight !== undefined ? -headerHeight : undefined,
          // }}
          style={transparentHeader ? { height: `calc(100% + ${headerHeight}px)` } : { height: '100%' }}
        >
          {children}
        </div>
      </div>
    </b2x.Page>
  );
};
