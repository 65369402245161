import './Breadcrumb.scss';

import { b2x } from '@b2x/react/src';

export const Breadcrumb = ({ alignment, ...otherProps }: b2x.BreadcrumbProps) => {
  return (
    <b2x.Breadcrumb
      {...otherProps}
      classNameObject={{
        a: 'text-decoration-none text-blue',
        component: 'my-3',
        ol: `m-0 justify-content-${alignment} lh-1`,
      }}
    />
  );
};
