import './DesktopHeader.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { Container } from './Container';
import { HeaderContentType } from './contentTypes';
import { DesktopHeader, LocaleToggle } from './DesktopHeader';
import { t } from './i18n/i18n';
import { Icon, isIconName } from './Icon';
import logo from './images/logo.svg';
import logoSmall from './images/logo-small.svg';
import { MobileHeader } from './MobileHeader';
import { SliderVertical } from './slider/SliderVertical';

export interface HeaderProps {}

export const Header = (props: HeaderProps) => {
  const content = b2x.useContent<HeaderContentType>('HEADER_CONTENT');
  const mobileHeaderRef = React.useRef<HTMLDivElement>(null);
  const desktopHeaderSecondRowRef = React.useRef<HTMLDivElement>(null);
  const { headerCheckout } = useAppContext();

  const { setHeaderHeight } = useAppStaticContext();

  const { width } = b2x.useWindowSize();

  const recalculateHeaderHeight = React.useCallback(() => {
    const currentBreakpoint = b2x.getCurrentBreakpoint();
    if (['xs', 'sm', 'md', 'lg'].includes(currentBreakpoint)) {
      // Mobile
      if (mobileHeaderRef.current) {
        setHeaderHeight(mobileHeaderRef.current.clientHeight);
      }
    } else {
      // Desktop
      if (desktopHeaderSecondRowRef.current) {
        setHeaderHeight(desktopHeaderSecondRowRef.current.clientHeight);
      }
    }
  }, [desktopHeaderSecondRowRef, mobileHeaderRef, setHeaderHeight]);

  React.useEffect(() => {
    recalculateHeaderHeight();
  }, [recalculateHeaderHeight, width]);

  const [activeCategory, setActiveCategory] = React.useState<b2x.MenuApiDto>();
  const [searchBoxVisible, setSearchBoxVisible] = React.useState<boolean>(false);
  const [localeBoxVisible, setLocaleBoxVisible] = React.useState<boolean>(false);

  const toggleLocaleBoxVisible = React.useCallback(() => {
    setActiveCategory(undefined);
    setSearchBoxVisible(false);
    setLocaleBoxVisible((prevState) => !prevState);
  }, []);

  return (
    <>
      {!headerCheckout ? (
        <div className="sticky-top">
          <TopBar content={content} toggleLocaleBoxVisible={toggleLocaleBoxVisible} />
          <MobileHeader
            content={content}
            innerRef={mobileHeaderRef}
            recalculateHeaderHeight={recalculateHeaderHeight}
            visibleUntil="xl"
          />
          <DesktopHeader
            activeCategory={activeCategory}
            content={content}
            firstRowRef={desktopHeaderSecondRowRef}
            localeBoxVisible={localeBoxVisible}
            recalculateHeaderHeight={recalculateHeaderHeight}
            searchBoxVisible={searchBoxVisible}
            setActiveCategory={setActiveCategory}
            setLocaleBoxVisible={setLocaleBoxVisible}
            setSearchBoxVisible={setSearchBoxVisible}
            toggleLocaleBoxVisible={toggleLocaleBoxVisible}
            visibleFrom="xl"
          />
        </div>
      ) : (
        <b2x.HeaderCheckout
          desktopCenterItemStyle="h5 m-0"
          desktopRightItemStyle="extra-small text-dark text-uppercase"
          logo={logo}
          logoSmall={logoSmall}
          mobileCenterItemStyle="extra-small mb-3 fw-light text-dark text-uppercase"
          mobileFrom={'lg'}
          recalculateHeaderHeight={recalculateHeaderHeight}
          wrapperStyle="shadow bg-white"
        />
      )}
    </>
  );
};

interface TopBarProps {
  content?: b2x.ContentApiDto<HeaderContentType>;
  toggleLocaleBoxVisible(): void;
}

const TopBar = ({ content, toggleLocaleBoxVisible }: TopBarProps) => {
  const { topBarRowRef } = useAppContext();
  const { setTopBarDesktopHeight, setTopBarRowRef } = useAppStaticContext();

  React.useEffect(() => {
    setTopBarDesktopHeight(topBarRowRef.current?.clientHeight);
  }, [setTopBarDesktopHeight, topBarRowRef]);

  return (
    <div
      className={'extra-small border-bottom'}
      ref={setTopBarRowRef}
      style={{
        /* background:
          content?.body.firstRow?.backgroundColor && !transparentHeader
            ? content.body.firstRow.backgroundColor
            : transparentHeader
            ? 'transparent'
            : '#FFF',
        color:
          content?.body.firstRow?.textColor && !transparentHeader
            ? content.body.firstRow.textColor
            : transparentHeader
            ? '#003D6C'
            : '#000000', */
        background: ' #FFF',
        color: '#003D6C',
      }}
    >
      <Container>
        <b2x.Row>
          <b2x.Col className="d-none d-lg-flex" size={4}>
            <LocaleToggle toggleLocaleBoxVisible={toggleLocaleBoxVisible} />
          </b2x.Col>
          <b2x.Col size={{ lg: 4, sm: 12 }}>
            {content?.body.firstRow?.centerColumn?.sliderVertical?.itemList ? (
              <SliderVertical itemList={content.body.firstRow.centerColumn.sliderVertical.itemList} />
            ) : (
              <span className="extra-small text-uppercase">{t('welcome')}</span>
            )}
          </b2x.Col>
          <b2x.Col className="d-none d-lg-flex align-items-center justify-content-end fw-bold" size={4}>
            {content?.body.firstRow?.rightColumn?.cta && (
              <b2x.DeprecatedCta
                className="text-reset text-decoration-none"
                cta={content.body.firstRow.rightColumn.cta}
              >
                {content.body.firstRow.rightColumn.cta.icon &&
                  isIconName(content.body.firstRow.rightColumn.cta.icon) && (
                    <Icon className="me-1" name={content.body.firstRow.rightColumn.cta.icon} size={16} />
                  )}
                {content.body.firstRow.rightColumn.cta.label}
              </b2x.DeprecatedCta>
            )}
          </b2x.Col>
        </b2x.Row>
      </Container>
    </div>
  );
};
