import { b2x } from '@b2x/react/src';
import React from 'react';

import { Box } from '../Box';
import { Button } from '../Button';
import { RegistrationFormContentType, WhoisPageContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { Page } from './Page';

export interface WhoisPageProps {}

export const WhoisPage = (props: WhoisPageProps) => {
  const [register, setRegister] = React.useState<boolean>(false);

  const handleButtonClick = React.useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setRegister(true);
  }, []);

  const handleCancelButtonClick = React.useCallback(() => {
    setRegister(false);
  }, []);
  const whoisContent = b2x.useContent<WhoisPageContentType>('WHOIS_PAGE_CONTENT');
  const registrationContent = b2x.useContent<RegistrationFormContentType>('REGISTRATION_FORM_CONTENT');

  const navigate = b2x.router.useNavigate();

  const handleLoginSuccess = React.useCallback(() => {
    navigate('/checkout');
  }, [navigate]);

  const handleRegistrationSuccess = React.useCallback(() => {
    navigate('/checkout');
  }, [navigate]);

  return (
    <Page headerCheckout powderLightBackground withGenericBackgroundImage="bottom">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6 col-xl-5 pb-4 pb-xl-0">
            <Box fullHeight>
              <div className="border-bottom mb-3 border-1">
                <h3 className="text-uppercase text-center mb-3 fw-bold text-blue">
                  {b2x.formatHtml(whoisContent?.body.continueAsRegisteredUser?.title)}
                </h3>
              </div>
              <div className="text-center mb-4">
                {b2x.formatHtml(whoisContent?.body.continueAsRegisteredUser?.subTitle)}
              </div>
              <b2x.LoginForm onSuccess={handleLoginSuccess} />
            </Box>
          </div>
          <div className="col-12 col-lg-6 col-xl-5">
            <Box fullHeight>
              {!register && (
                <div className="mb-4 ">
                  <h3 className="text-uppercase fw-bold text-blue text-center mb-1 d-none d-md-block">
                    {b2x.formatHtml(whoisContent?.body.continueAsGuest?.title)}
                  </h3>
                  <div className="border-bottom mb-3 border-2 d-md-none">
                    <h5 className="text-uppercase text-center mb-3">
                      {b2x.formatHtml(whoisContent?.body.continueAsGuest?.title)}
                    </h5>
                  </div>
                  {
                    <div className="text-center mb-3 mb-md-5">
                      {b2x.formatHtml(whoisContent?.body.continueAsGuest?.subTitle)}
                    </div>
                  }
                  <div className="mb-5">
                    <b2x.CartEmailFormHelper variant="whois">
                      {({ fieldsHelper, formik }) => (
                        <>
                          <b2x.FormGroup {...fieldsHelper.email.formGroup} label={undefined}>
                            <b2x.TextInput {...fieldsHelper.email.textInput} />
                          </b2x.FormGroup>
                          <b2x.FormGroup {...fieldsHelper.privacy.formGroup}>
                            <b2x.Checkbox
                              className="extra-small fw-light text-dark"
                              {...fieldsHelper.privacy.checkbox}
                            />
                          </b2x.FormGroup>
                          <div className="d-grid">
                            <b2x.Button {...fieldsHelper.buttons.submit} />
                          </div>
                        </>
                      )}
                    </b2x.CartEmailFormHelper>
                  </div>
                  {
                    <div className="text-center fw-light mb-3">
                      {b2x.formatHtml(whoisContent?.body.continueAsGuest?.registerMe?.content)}
                    </div>
                  }
                  <div className="d-grid">
                    <Button label={t('misc.register')} onClick={handleButtonClick} variant="outline-primary" />
                  </div>
                </div>
              )}
              {register && (
                <div className="mb-4">
                  <h3 className="text-uppercase text-center mb-3 fw-medium">
                    {b2x.formatHtml(whoisContent?.body.continueAsGuest?.title)}
                  </h3>
                  <div className="text-center fw-light mb-3">
                    {b2x.formatHtml(registrationContent?.body.newsletterDiscount)}
                  </div>
                  <b2x.CustomerForm onCancel={handleCancelButtonClick} onSuccess={handleRegistrationSuccess} />
                </div>
              )}
            </Box>
          </div>
        </div>
      </div>
    </Page>
  );
};
