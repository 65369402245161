import React from 'react';

import { b2x } from '.';

export interface UseArticlesOptions {
  blogCodePage: string;
  numArticlesShowed?: number;
}

export const useArticles = <ContentType,>({ blogCodePage, numArticlesShowed = 12 }: UseArticlesOptions) => {
  const [articlesIds, setArticlesIds] = React.useState<Array<string>>();
  const [articles, setArticles] = React.useState<Array<b2x.PageApiDto<ContentType>>>();
  const [fetching, setFetching] = React.useState<boolean>(true);

  const { getPageByPath, searchPages } = b2x.usePagesApi();
  const location = b2x.router.useLocation();

  const [searchParams] = b2x.router.useSearchParams();
  const pageNum = searchParams.get('pageNum') ? Number(searchParams.get('pageNum')) : 1;

  React.useEffect(() => {
    getPageByPath(location.pathname).then((response) => {
      if (response.data.code === blogCodePage) {
        getPageByPath(location.pathname, {
          populate: { children: { children: true } },
        }).then((resp) => {
          const _articles = resp.data.children?.reduce<Array<b2x.PageApiDto<ContentType>>>((prev, curr) => {
            if (curr.children) {
              prev = prev.concat(curr.children as Array<b2x.PageApiDto<ContentType>>);
            }
            return prev;
          }, []);

          setArticlesIds(_articles?.map((article) => article.id));
        });
      } else {
        getPageByPath(location.pathname, { populate: { children: true } }).then((resp) => {
          setArticlesIds((resp.data.children as Array<b2x.PageApiDto<ContentType>>).map((article) => article.id));
        });
      }
    });
  }, [blogCodePage, getPageByPath, location.pathname]);

  React.useEffect(() => {
    const initialId = pageNum > 1 ? (pageNum - 1) * numArticlesShowed : 0;
    const finalId = pageNum * numArticlesShowed;
    if (articlesIds !== undefined && articlesIds.length > 0) {
      setFetching(true);
      searchPages<ContentType>(
        articlesIds.slice(initialId, finalId),
        {
          populate: { breadcrumb: true, content: true },
        },
        { silent: false }
      )
        .then((resp) => {
          setArticles(resp.data);
        })
        .finally(() => {
          setFetching(false);
        });
    } else {
      setArticles(undefined);
    }
  }, [articlesIds, numArticlesShowed, searchPages, pageNum]);

  const fetchMore = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (articlesIds !== undefined && articles !== undefined) {
        setFetching(true);
        searchPages<ContentType>(articlesIds.slice(articles.length, articles.length + numArticlesShowed), {
          populate: { breadcrumb: true, content: true },
        })
          .then((resp) => {
            setArticles((prevState) => prevState?.concat(resp.data));
          })
          .finally(() => {
            setFetching(false);
          });
      }
    },
    [articles, articlesIds, numArticlesShowed, searchPages]
  );

  const otherItemsToShow = articlesIds !== undefined && articles !== undefined && articles.length < articlesIds.length;
  const noArticlesFound = !articles && !fetching;
  const pagesCount = articlesIds ? Math.ceil(articlesIds.length / numArticlesShowed) : 0;

  return { articles, fetchMore, fetching, noArticlesFound, otherItemsToShow, pagesCount };
};
